import React, { useContext } from "react";
import ScrollToTop from '../ScrollToTop' 

import AppContext from '../context'

import AwardsImage from '../images/awards.jpg'
import TalkAboutItImage from '../images/talkaboutit.jpg'
import BetterElsewhereImage from '../images/betterelsewhere.jpg'
import KitImage from '../images/kit.jpg'
import NeedHelpImage from '../images/need-help.jpg'
import TalkaboutitImage from '../images/talk-about-it.svg'
import BetterofferImage from '../images/better-offer.svg'
import BusinessneedsImage from '../images/business-needs.svg'
const Submitted = props => {

  const { verifyForm: { full_name, email_address }  } = useContext(AppContext)
   
  return (
    <>
    <div className={'header-panel'}>
      <div className={'row'}>
        <div className={'col-10 offset-1 col-lg-8 offset-lg-2 text-center'}>
          <h1>REQUEST SUBMITTED<br /><span class="blue">NEXT STEPS</span><span class="times blue">.</span></h1>
          <h2>{full_name} ({email_address}), who is the Authorised Signatory/Billing contact, has been emailed in order to authorise this request and terminate the contract relating to the services you selected.</h2>
          <small>Once they have clicked on the authorisation link included in the email, the request will be processed.</small>
        </div>
      </div>
    </div>
    <div className={"container"}>
    <div className={'row tips-container'}>
        <div className={'col-10 offset-1 offset-lg-0 col-lg-4  position-relative'}>
          <div class="industry-box-inner"></div>
          <div class="industry-stat-box light-bg text-center">
            <img src={TalkaboutitImage} alt="Let's talk about it?" className="img-fluid icon-tips" />
            <h3>Want to talk<br />about it?</h3>
            <p>Not happy with how things have gone? Let’s have a chat and see if we can make things better.</p>
            <a href="https://www.plan.com/support" class="btn btn-primary">Talk to Us</a>
          </div>
        </div>
        <div className={'col-10 offset-1 offset-lg-0 col-lg-4 mt-4 mt-lg-0 position-relative'}>
        <div class="industry-box-inner"></div>
          <div class="industry-stat-box light-bg text-center">
            <img src={BetterofferImage} alt="Better offer elsewhere?" className="img-fluid icon-tips" />
            <h3>Better offer elsewhere?</h3>
            <p>With constantly evolving software, tariffs and bolt-ons, why not double check you're not missing out.</p>
            <a href="https://www.plan.com/" class="btn btn-primary">Double Check</a>
          </div>
        </div>
        <div className={'col-10 offset-1 offset-lg-0 col-lg-4 mt-4 mt-lg-0 position-relative'}>
        <div class="industry-box-inner"></div>
          <div class="industry-stat-box light-bg text-center">
            <img src={BusinessneedsImage} alt="Your business needs" className="img-fluid icon-tips" />
            <h3>Your<br />  business needs</h3>
            <p>Our award winning portal with over 100 easy to use tools and features can take you to the next level. </p>
            <a href="https://www.plan.com/features" class="btn btn-primary">view features</a>
          </div>
        </div>
      </div>
      <ScrollToTop />
      <div className={'row mt-5'}>
        <div className={'col-10 offset-1 col-lg-8 offset-lg-2 main-section text-center'}>
        <h2>Next steps<span className={'times blue'}>.</span></h2>
        <p>The PACs or STACs associated with the services you selected will be issued within two working days of the request's authorisation.</p>
        <p>The disconnections associated with the services you selected will take place on the dates requested as long as they are authorised 30 days prior to the dates specified.</p>
        <p>If there are any issues relating to the Termination, we will contact you at the earliest possible convenience.</p>
        <p><strong>Final invoices,</strong> including any related Termination Fees, will be generated and sent once a service has been transferred away or disconnected from the plan.com network.</p>

        <div className={'d-flex justify-content-center text-center'}><p className="mt-4"><a href="https://www.plan.com/cancel" target="_blank">Click here</a> to read our full Terms &amp; Conditions before cancelling your contract.</p></div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Submitted
