import React from 'react'
import ScrollToTop from '../ScrollToTop' 

import AwardsImage from '../images/awards.jpg'
import TalkAboutItImage from '../images/talkaboutit.jpg'
import BetterElsewhereImage from '../images/betterelsewhere.jpg'
import KitImage from '../images/kit.jpg'
import NeedHelpImage from '../images/need-help.jpg'


export default () => {
    return (
    <div className={"container"}>
    <ScrollToTop />
    <div className={'row mt-5'}>
    <div className={'col-lg-7 main-section'}>
      <h1><span className={"text-primary"}>Request<br />authorised:</span><br />next steps</h1>
      <hr className={"thin-blue"} />
      <p>You have now authorised the service transfer or disconnection request for your plan.com service(s).</p>
      <h2>What happens now?</h2>
      <p><strong className="subheading">Transfers</strong><br />Porting Authorisation Codes (PACs) and Service Termination Authorisation Codes (STACs) will be issued within two working days from the point of authorisation*</p>
      <p><strong className="subheading">Disconnections</strong><br />Disconnections will take place on the dates requested, which must be at least 30 days after the authorisation*</p>
      <p>If there are any issues relating to the Termination, we will contact you at the earliest possible convenience.</p>
      <p><small>*The point of authorisation was when the link was clicked in the approval email</small></p>
      <h2>We’re sorry to see you go</h2>
      <p>The loss of any service from the plan.com network is always a concern to us, if you feel you've not received the quality of service we pride ourselves on, we'd like to do everything we can to rectify this and see you stay a happy plan.com customer.</p>
      <p>There is still time to discuss your options, please feel free to call us on <strong>+44(0)3300 88 18 18</strong> or email <a href="mailto:help@plan.com">help@plan.com</a> </p>
  </div>

  {/* CAROUSEL */}

        <div className={'col-lg-4 offset-1'}>
          <img src={AwardsImage} alt="Our Awards" className="img-fluid awards d-none d-lg-block" />
            
            <div >
              <img src={BetterElsewhereImage} alt="Better off Elsewhere" className="img-fluid mt-4" />

              <h2 className="mt-3">Better offer elsewhere?</h2>
              <hr className={'thin-blue second'} />
              <p>{/*We pride ourselves on the level of service we provide business customers.*/}If you think you can get a better offer elsewhere, let us know before you take the plunge.</p>
              <a className={'button float-right'} href="https://www.plan.com/support#support2" target="_blank">double check</a>
              <img src={KitImage} alt="Your Business Needs" className="img-fluid mt-4" />
              <h2 className="mt-3">Your business needs</h2>
              <hr className={'thin-blue second'} />
              <p>The latest handsets with affordable tariffs and generous allowances designed around your business.</p>
              <a className={'button float-right'} href="https://www.plan.com/support#support2" target="_blank">go bespoke</a>

            </div>
          </div>
        </div>
    </div>
  );
};
