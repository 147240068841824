import React, { useState }  from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


export default props  => {


    const [modalVisible, setModalVisible]  = useState(true)
  
    const toggle = () => setModalVisible(!modalVisible)

    return (
        <>
        <div>
          <Modal isOpen={modalVisible} toggle={toggle} >
            <ModalHeader toggle={toggle}>IMPORTANT INFORMATION<span>.</span></ModalHeader>
            <ModalBody>
                <p>Before you start your PAC/disconnection request please ensure you have all the required account information you need to hand as incorrect data entry will result in your PAC/disconnection request failing.</p>
                <p>You will need the following information: </p>
                <p><strong className={'dark-blue'}>Billing / Account holder’s email address</strong> – This is the email address to where your plan.com invoice is sent to every month</p>
                <p><strong className={'dark-blue'}>Business postcode</strong> – This is the postcode associated with your business, which can be the trading address, registered address or the billing address.</p>
                <p><strong className={'dark-blue'}>Business Name</strong> – This is the name of your business. You can find your business name on the top of your plan.com invoice</p>
                <p><strong className={'dark-blue'}>Mobile Number</strong> – This is your plan.com mobile number or a number that is already associated with your plan.com account.</p>
                <p>If you require assistance, please call our Customer Service team on <strong className={'dark-blue'}>03300 88 18 18</strong> or <a href="mailto:help@plan.com">help@plan.com</a>.</p>
            </ModalBody>
          </Modal>
        </div>
        </>
    )
  }
  