import React, { useEffect } from "react";

// Component that attaches scroll to top hanler on router change
// renders nothing, just attaches side effects
const ScrollToTop = props => {
  // this assumes that current router state is accessed via hook
  // but it does not matter, pathname and search (or that ever) may come from props, context, etc.

  // just run the effect on pathname and/or search change
  useEffect(() => {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
  });

  return null
  
};

export default ScrollToTop;
