import React, { useContext } from 'react'
import  AppContext from '../context'

import ConfirmHelp from './confirm_help'



export default () => {
    const {
        confirmLiabilities,
        setConfirmLiabilities,
    } = useContext(AppContext)



    
    
    return (


            <div className={'row'}>
                <div className={'col-1 offset-1 pb-3 d-flex justify-content-end mt-1'}>
                    
                        <input type={'checkbox'} checked={confirmLiabilities} onChange={(e) => setConfirmLiabilities(e.target.checked)} />
                    
                </div>
                <div className={'col-9 pb-3 d-flex'}>
                    <p>I confirm that the company or trading entity is solvent and able to meet its liabilities as they fall due.</p><ConfirmHelp />
                </div>
            </div>
)
}