import React, { useContext } from "react";
import AppContext from "../context";

import SignalIssueImage from '../images/signal-issue.jpg'
import MissoldImage from '../images/mis-sold.jpg'
import NoBuyoutsImage from '../images/no-buyouts.jpg'
import ContractLengthImage from '../images/contract-length.jpg'
import BankruptcyImage from '../images/bankruptcy.jpg'
import BetterDealImage from '../images/better-deal.jpg'
import PoorCustomerServiceImage from '../images/poor-customer-service.jpg'
import DeceasedImage from '../images/deceased.jpg'

export default () => {
  const {
    disconnectionReason
  } = useContext(AppContext);

  return (
    <React.Fragment>
     
      {disconnectionReason === 5 && (
        
        <div className={"disconnect-reason"}>
          <div className={"row"}>
            <div className={"col-12"}>
              <img src={SignalIssueImage} alt="Signal Issue" className="img-fluid mb-4" />
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-lg-6 pt-3"}>
              <p><strong>Quick fix?</strong></p>
              <p>The first step to resolving a signal issue is to identify if the issue is due to your current location or environment, for example within your office or home. You can determine whether this is a factor by taking your phone to a different location and finding out if you experience the same signal issues. You can also check the O2 signal checker for coverage in your location.</p>
              <p>If you do experience the same issues in different locations, it could be the fault of the handset. You can check if it is the fault of the handset by trying the SIM card in a different plan.com or O2 mobile handset.</p>
              <p>The next step would be to try a different SIM card, which can be ordered via the Partner who brought you to plan.com, or through plan.com directly.</p>
            </div>
            <div className={"col-lg-6 pt-3"}>
              <p><strong>Can anything else improve the signal strength or quality? </strong></p>
              <p>In some cases, using 4G or Wifi Calling, or installing an O2 Boostbox can help to overcome signal issues. Please call us on 03300 88 18 18 or email <a href="mailto:help@plan.com">help@plan.com</a> to find out more. </p>
              <p><strong>Please don’t give up on us!</strong></p>
              <p>plan.com is a multi-award-winning business phone and communications provider. Working in close partnership with our network of business experts and the O2 network, we are proud to offer businesses the tools, insight and support that you need to stay connected and in control. </p>
              <p>We understand that signal issues are a huge source of frustration, but we’re determined to show you that we can provide the level or service and support that you expect. </p>
            </div>
          </div>
        </div>

  )}

{disconnectionReason === 60 && (
        
  <div className={"disconnect-reason"}>
    <div className={"row"}>
      <div className={"col-12"}>
        <img src={MissoldImage} alt="Mis Sold" className="img-fluid mb-4" />
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-lg-6 pt-3"}>
        <p><strong>How can plan.com help?</strong></p>
        <p>We investigate any and all suggestions of mis-selling. plan.com does not sell direct to customers so these investigations involve the partner who brought you to plan.com. We have access to the call recordings of conversations that directly involve the selling of contracts. We also have access to the information provided by you (the customer) and the partner on our eContracts. </p>
        <p>Therefore, if you do believe you were mis-sold a contract, please contact us via 03300 88 18 18 or emailing <a href="mailto:help@plan.com">help@plan.com</a> if you have not done so already. </p>
      </div>
      <div className={"col-lg-6 pt-3"}>
      <p><strong>&nbsp;</strong></p>
        <p>If the relationship between your business and the partner who brought you to plan.com has broken down as a result of these issues, we can discuss the option to move your ongoing support to a different affiliate. </p>
        <p><strong>Please don’t give up on us!</strong></p>
        <p>plan.com is a multi-award-winning business phone and communications provider. Working in close partnership with our network of business experts and the O2 network, we are proud to offer businesses the tools, insight and support that you need to stay connected and in control. </p>
      </div>
    </div>
  </div>

)}

{disconnectionReason === 61 && (
        
  <div className={"disconnect-reason"}>
    <div className={"row"}>
      <div className={"col-12"}>
        <img src={NoBuyoutsImage} alt="No buyouts" className="img-fluid mb-4" />
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-lg-6 pt-3"}>
        <p><strong>This is a serious matter</strong></p>
        <p>If you have not already contacted us directly about this, we encourage you to do so by calling 03300 88 18 18 or emailing help@plan.com if you have not done so already. </p>
        <p><strong>What can we do?</strong></p>
        <p>We have a close business relationship with our partners, so if you feel that you are getting nowhere in your discussions about buyouts or discounts with the partner who brought you to plan.com, please feel free to ask one of our team to intervene. </p>
        <p>If the relationship between your business and the partner who brought you to plan.com has broken down as a result of these issues, we can discuss the option to move your ongoing support to a different affiliate. </p>
      </div>
      <div className={"col-lg-6 pt-3"}>
        <p><strong>Please don’t give up on us!</strong></p>
        <p>plan.com is a multi-award-winning business phone and communications provider. Working in close partnership with our network of business experts and the O2 network, we are proud to offer businesses the tools, insight and support that you need to stay connected and in control. </p>
        <p>We understand that buyouts or discounts may have been a fundamental reason that you chose our network. We take these matters very seriously and will work hard on your behalf to resolve the matter if you want us to intervene. </p>
      </div>
    </div>
  </div>

)}

{disconnectionReason === 62 && (
        
  <div className={"disconnect-reason"}>
    <div className={"row"}>
      <div className={"col-12"}>
        <img src={ContractLengthImage} alt="Contract Length" className="img-fluid mb-4" />
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-lg-6 pt-3"}>
        <p><strong>How can plan.com help?</strong></p>
        <p>We investigate all suggestions of mis-selling. plan.com does not sell direct to customers so these investigations involve the partner who brought you to plan.com. We have access to the call recordings of conversations that directly involve the selling of contracts. We also have access to the information provided by you (the customer) and the partner on our eContracts. </p>
        <p>Therefore, if you do believe you were entering into a contract of a different length, please contact us via 03300 88 18 18 or emailing <a href="mailto:help@plan.com">help@plan.com</a> if you have not done so already.</p>
        <p>If the relationship between your business and the partner who brought you to plan.com has broken down as a result of these issues, we can discuss the option to move your ongoing support to a different affiliate. </p>
      </div>
      <div className={"col-lg-6 pt-3"}>
        <p><strong>Please don’t give up on us!</strong></p>
        <p>plan.com is a multi-award-winning business phone and communications provider. Working in close partnership with our network of business experts and the O2 network, we are proud to offer businesses the tools, insight and support that you need to stay connected and in control. </p>
        <p>We understand that the length of a contract is an important factor in the decision to enter into a contract, and we’re determined to show you that we can provide the level or service and support that you expect by helping you to resolve this matter. </p>
      </div>
    </div>
  </div>

)}


{disconnectionReason === 34 && (
        
  <div className={"disconnect-reason"}>
    <div className={"row"}>
      <div className={"col-12"}>
        <img src={BankruptcyImage} alt="Bankruptcy, IVAs, CVAs or ceased trading" className="img-fluid mb-4" />
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-lg-6 pt-3"}>
        <p>In some of these cases, we are aware that mobile numbers may need to be transferred to different businesses, individuals or entities.</p>
      </div>
      <div className={"col-lg-6 pt-3"}>
      <p>If you have not done so already, please contact us directly via 03300 88 18 18 or email <a href="mailto:help@plan.com">help@plan.com</a> if you want to discuss your options, or have any questions regarding the processes involved when a business ceases trading or is in financial difficulty. </p>


        
      </div>
    </div>
  </div>

)}

{disconnectionReason === 23 && (
        
  <div className={"disconnect-reason"}>
    <div className={"row"}>
      <div className={"col-12"}>
        <img src={BetterDealImage} alt="Better Deal Elsewhere" className="img-fluid mb-4" />
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-lg-6 pt-3"}>
        <p><strong>The plan.com difference</strong></p>
        <p>plan.com is a multi-award-winning business phone and communications provider. Working in close partnership with our network of business experts and the O2 network, we are proud to offer business the tools, insight and support that you need to stay connected and in control. Here are just some of the benefits of staying with plan.com:</p>
        <p><strong>We’re strictly business</strong></p>
        <p>We only provide our service to businesses, which means we’re 100% focussed on the specific needs of businesses, many of whom have a different needs and higher standards than consumers. </p>
        <p><strong>my.plan</strong></p>
        <p>We use the latest mobile, digital technology to deliver industry-leading tools and insight directly into your hands.</p>
        <p>Our online customer portal gives you important, up-to-date information about your phones and your account. </p>
        <p><strong>Partner portal</strong></p>
        <p>The business partner who brought you to plan.com has an advanced online portal of their own. As your first line of support, they are there to help proactively manage or respond quickly to your issues. The portal enables them to do this from any device, at any time.</p>
      </div>
      <div className={"col-lg-6 pt-3"}>
      <p><strong>Joined-up communications</strong></p>
      <p>Mobile, hosted voice, broadband, software... we can take care of all your business communications so you can focus on your business. </p>
      <p>Combine these different channels and we will combine everything into one bill, managed via one online portal, with our team of experts working together to give you joined-up support.</p>
      <p><strong>Customer support the way it should be</strong></p>
      <p>We'll give you a straightforward answer rather than sending you round the houses. Our dedicated UK-based support teams are on-hand to support you, if and when you need it.</p>
      <p><strong>Stay alert & avoid unexpected costs...</strong></p>
      <p>...with a series of customisable email and SMS alerts and notifications. Alerts tell you if any of your services move outside your monthly data, text or voice allowances.</p>
      <p>Bars can be set up to automatically activate and protect against unexpected costs.</p>

        
      </div>
    </div>
  </div>

)}

{disconnectionReason === 19 && (
        
  <div className={"disconnect-reason"}>
    <div className={"row"}>
      <div className={"col-12"}>
        <img src={PoorCustomerServiceImage} alt="Poor Customer Service" className="img-fluid mb-4" />
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-lg-6 pt-3"}>
        <p>We know that customer service is a fundamental to your relationship with your phone provider and that up to now, your experience has not met the standard that you expect or deserve. </p>
        <p>We understand that a breakdown in your relationship with us or the partner who brought you to plan.com can be a huge source of frustration, but we’re determined to show you that we can provide the level or service and support that you expect. </p>
        <p>If the relationship between your business and the partner who brought you to plan.com has broken down as a result of these issues, we can discuss the option to move your ongoing support to a different affiliate.</p>
      </div>
      <div className={"col-lg-6 pt-3"}>
      <p><strong>Please don’t give up on us!</strong></p>
      <p>plan.com is a multi-award-winning business phone and communications provider. Working in close partnership with our network of business experts and the O2 network, we are proud to offer business the tools, insight and support that you need to stay connected and in control. </p>
      <p><strong>my.plan – another great way to feel supported</strong></p>
      <p>We use the latest mobile, digital technology to deliver industry-leading tools and insight directly into your hands. </p>
      <p>Our online customer portal gives you important, up-to-date information about your phones and your account. </p>


        
      </div>
    </div>
  </div>

)}

{disconnectionReason === 18 && (
        
  <div className={"disconnect-reason"}>
    <div className={"row"}>
      <div className={"col-12"}>
        <img src={DeceasedImage} alt="Deceased" className="img-fluid mb-4" />
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-6 pt-3"}>
        <p>In order to protect our customers against fraud or malicious attempts to disrupt a business, we do require a copy of a death certificate in order to transfer a phone number or disconnect it from the network. However, there are several options available under these circumstances, such as for business numbers that are still in operation or wish to remain active. </p>
      </div>
      <div className={"col-6 pt-3"}>
      <p>If you have not done so already, please contact us directly via 03300 88 18 18 or email <a href="mailto:help@plan.com">help@plan.com</a> if you want to discuss your options or have any questions regarding the processes involved when a contract or service owner has passed away. </p>



      </div>
    </div>
  </div>

)}

{disconnectionReason === 34 && (
        
  <div className={"disconnect-reason"}>
    <div className={"row"}>
      <div className={"col-12"}>
        <img src={BankruptcyImage} alt="Bankruptcy, IVAs, CVAs or ceased trading" className="img-fluid mb-4" />
      </div>
    </div>
    <div className={"row"}>
      <div className={"col-lg-6 pt-3"}>
        <p>In some of these cases, we are aware that mobile numbers may need to be transferred to different businesses, individuals or entities.</p>
      </div>
      <div className={"col-lg-6 pt-3"}>
      <p>If you have not done so already, please contact us directly via 03300 88 18 18 or email <a href="mailto:help@plan.com">help@plan.com</a> if you want to discuss your options, or have any questions regarding the processes involved when a business ceases trading or is in financial difficulty. </p>

      </div>
    </div>
  </div>

)}

    </React.Fragment>
  );
};

