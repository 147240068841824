import React from 'react'

import ServiceList from "./service_list";
import ConfirmLiabilities from "./confirm_liabilities";
import Buttons from "./buttons";
import Address from "./address";
import Reason from "./reason";

import TalkaboutitImage from '../images/talk-about-it.svg'
import BetterofferImage from '../images/better-offer.svg'
import BusinessneedsImage from '../images/business-needs.svg'

import PacHelp from './pac_help'
// import TerminationHelp from './termination_help'

const RequestDetails = props => {
    return (
        <>
            <div className={'header-panel'}>
                <div className={'row'}>
                    <div className={'col-10 offset-1 col-lg-8 offset-lg-2 text-center'}>
                        <h1>REQUEST<br /><span class="blue">DETAILS</span><span class="times blue">.</span></h1>
                        <h2> A transfer or disconnection will lead to a service becoming unavailable. For this reason, please ensure the details and requests that you enter are accurate.</h2>
                    </div>
                </div>
            </div>
            <div className={'container'}>
                <div className={'row tips-container'}>
                    <div className={'col-10 offset-1 offset-lg-0 col-lg-4 position-relative'}>
                        <div class="industry-box-inner"></div>
                        <div class="industry-stat-box light-bg text-center">
                            <img src={TalkaboutitImage} alt="Let's talk about it?" className="img-fluid icon-tips" />
                            <h3>Want to talk<br />about it?</h3>
                            <p>Not happy with how things have gone? Let’s have a chat and see if we can make things better.</p>
                            <a href="https://www.plan.com/support" class="btn btn-primary">Talk to Us</a>
                        </div>
                    </div>
                    <div className={'col-10 offset-1 offset-lg-0 col-lg-4 mt-4 mt-lg-0 position-relative'}>
                        <div class="industry-box-inner"></div>
                        <div class="industry-stat-box light-bg text-center">
                            <img src={BetterofferImage} alt="Better offer elsewhere?" className="img-fluid icon-tips" />
                            <h3>Better offer elsewhere?</h3>
                            <p>With constantly evolving software, tariffs and bolt-ons, why not double check you're not missing out.</p>
                            <a href="https://www.plan.com/" class="btn btn-primary">Double Check</a>
                        </div>
                    </div>
                    <div className={'col-10 offset-1 offset-lg-0 col-lg-4 mt-4 mt-lg-0 position-relative'}>
                        <div class="industry-box-inner"></div>
                        <div class="industry-stat-box light-bg text-center">
                            <img src={BusinessneedsImage} alt="Your business needs" className="img-fluid icon-tips" />
                            <h3>Your<br />  business needs</h3>
                            <p>Our award winning portal with over 100 easy to use tools and features can take you to the next level. </p>
                            <a href="https://www.plan.com/features" class="btn btn-primary">view features</a>
                        </div>
                    </div>
                </div>
                <div className={'row mt-4'}>


                    <Address />

                    <PacHelp />
                    {/* <TerminationHelp /> */}
                    <ServiceList />
                    <Reason />
                    <ConfirmLiabilities />
                    <Buttons />
                    <div className={'d-flex justify-content-center text-center'}><p className="mt-4"><a href="https://www.plan.com/cancel" target="_blank">Click here</a> to read our full Terms &amp; Conditions before cancelling your contract.</p></div>
                </div>
            </div>
        </>
    )
}

export default RequestDetails