import React, { useContext, useEffect, useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha'

import { Link } from "react-router-dom";

import ReactCodeInput from 'react-code-input'

import AppContext from '../context'

import { createRequest, validateCode } from '../Api'

import IdentityHelp from './identity_help'
import SecurityHelp from './security_help'
import StartHelp from './start_help'


import AwardsImage from '../images/awards.jpg'
import TalkAboutItImage from '../images/talkaboutit.jpg'
import BetterElsewhereImage from '../images/betterelsewhere.jpg'
import KitImage from '../images/kit.jpg'
import NeedHelpImage from '../images/need-help.jpg'
import TalkaboutitImage from '../images/talk-about-it.svg'
import BetterofferImage from '../images/better-offer.svg'
import BusinessneedsImage from '../images/business-needs.svg'

const VerifyUser = props => {

  // const [state, dispatch] = useReducer(reducer, {}, init);
  // const [mobileNumber, setMobileNumber] = useInputState("07774835528");

  const [errorMessage, setErrorMessage] = useState(null)
  const [verifyCode, setVerifyCode] = useState("")
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [pendingCreate, setPendingCreate] = useState(false)

  const {
    recaptcha_key,
    verified,setVerified,
    verifyForm, setVerifyForm,
    codeRequested, setCodeRequested,
    setRequestToken, requestToken
  } = useContext(AppContext)

  const setVerifyFormField = (e) => {
    let newObj = {...verifyForm, [e.target.name]: e.target.value}
    console.log(newObj)
    setVerifyForm(newObj)
  }


  useEffect(()=>{
    if (verifyCode && verifyCode.length === 5) {
      validateCode(requestToken, verifyCode)
      .then(result=>{
        setVerified(true)
      })      
      .catch(result=>{
        // setCodeRequested(false)

        result.json().then(data=>{
          console.log("failed?", data);
          // setCodeRequested(true)

          setErrorMessage(data.description)
          setVerifyCode("")
          setVerified(false)

        })
      })

    }
  }, [verifyCode])

const handleSendSecurityCode = () => {
  setPendingCreate(true)
  setErrorMessage("")

  createRequest(verifyForm)
  .then(response => {
    if (response.request_token) {
      setCodeRequested(true)
      setRequestToken(response.request_token)

    } else {
      setErrorMessage(response)
      setPendingCreate(false)
    }
  })
  .catch(error=>{ 
    console.log("error", error)
      error.json().then(json =>{ setErrorMessage("Failed to generate code: "  + json.message ) })
      setPendingCreate(false)
  }
    )

}

const [formValid, setFormValid] = useState(false)


useEffect(() => {
  setFormValid(verifyForm.mobile_number && verifyForm.mobile_number.length >= 11
    && verifyForm.postcode && verifyForm.postcode.length >= 5) ;
  
}, [verifyForm])



 
// useEffect(() => {


// })


  // const verifyNumber = () => {
  //     dispatch( { type: "set_status", payload: { status: "searching..." } })
  //     findService(mobileNumber)
  //     .then(result => dispatch({ type: "set_verify_result", payload: result }))
  //     .catch(result => dispatch({ type: "set_error_message", payload: result }))
      
  // }


  return (
    
    <div className="">
    <StartHelp />
    <div className={'header-panel'}>
      <div className={'row'}>
        <div className={'col-10 offset-1 col-lg-8 offset-lg-2 text-center'}>
          <h1>PAC &amp; DISCONNECTION<br /><span class="blue">REQUEST</span><span class="times blue">.</span></h1>
          <h2>If you feel you’ve not received the quality of service we pride ourselves on, we’d like to do everything we can to rectify this and see you remain with us as a plan.com customer.</h2>
        </div>
      </div>
    </div>
    <div className="container">
    <div className={'row tips-container'}>
        <div className={'col-10 offset-1 offset-lg-0 col-lg-4 position-relative'}>
          <div class="industry-box-inner"></div>
          <div class="industry-stat-box light-bg text-center">
            <img src={TalkaboutitImage} alt="Let's talk about it?" className="img-fluid icon-tips" />
            <h3>Want to talk<br />about it?</h3>
            <p>Not happy with how things have gone? Let’s have a chat and see if we can make things better.</p>
            <a href="https://www.plan.com/support" class="btn btn-primary">Talk to Us</a>
          </div>
        </div>
        <div className={'col-10 offset-1 offset-lg-0 col-lg-4 mt-4 mt-lg-0 position-relative'}>
        <div class="industry-box-inner"></div>
          <div class="industry-stat-box light-bg text-center">
            <img src={BetterofferImage} alt="Better offer elsewhere?" className="img-fluid icon-tips" />
            <h3>Better offer elsewhere?</h3>
            <p>With constantly evolving software, tariffs and bolt-ons, why not double check you're not missing out.</p>
            <a href="https://www.plan.com/" class="btn btn-primary">Double Check</a>
          </div>
        </div>
        <div className={'col-10 offset-1 offset-lg-0 col-lg-4 mt-4 mt-lg-0  position-relative'}>
        <div class="industry-box-inner"></div>
          <div class="industry-stat-box light-bg text-center">
            <img src={BusinessneedsImage} alt="Your business needs" className="img-fluid icon-tips" />
            <h3>Your<br />  business needs</h3>
            <p>Our award winning portal with over 100 easy to use tools and features can take you to the next level. </p>
            <a href="https://www.plan.com/features" class="btn btn-primary">view features</a>
          </div>
        </div>
      </div>
      <div className={'row mt-4'}>

        <div className={'col-10 offset-1 col-lg-8 offset-lg-2 main-section text-center'}>

          <h2 className={'mt-5'}>Identity and validation<span class="times blue">.</span></h2>
          <p>We take the security of your information and your business information very seriously. Before you start, we advise that you have a recent plan.com bill to-hand or log in to my.plan, which provide some important details needed to validate your identity.</p>
          <p>All fields are mandatory. Please ensure that you enter either the account holder or billing email address and the business’s postcode, NOT your own personal information.</p>
          <p>Your business postcode and business name can be found on your plan.com invoice.</p>
          <p>Alternatively, you can access your account’s billing email address, postcode and business name via my.plan. If you have no access to a recent bill or my.plan, please contact our Customer Service team on <strong>03300 88 18 18</strong> or <a href="mailto:help@plan.com" class={'link'}>help@plan.com</a>. </p>
         
          <div className={'form-box'}>

            <div className={'row'}>
              <div className={'col-12 col-md-6'}>
                <label>Your Full Name</label>
                <input disabled={codeRequested} type={'text'} placeholder={'YOUR FULL NAME'} name={'full_name'} className={'input-boxes'} onChange={setVerifyFormField} value={verifyForm.full_name || ''} />
              </div>
              <div className={'col-12 col-md-6 pt-3 pt-md-0'}>
              <label>Business Name</label>
                <input disabled={codeRequested} placeholder={'BUSINESS NAME'} name={'business_name'} className={'input-boxes'} onChange={setVerifyFormField} value={verifyForm.business_name || ''} />
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-12 col-md-6 pt-3'}>
              <label>Billing Email Address</label>
                <input disabled={codeRequested} placeholder={'BILLING EMAIL ADDRESS'} name={'email_address'} className={'input-boxes'} onChange={setVerifyFormField} value={verifyForm.email_address || ''} />
              </div>
              <div className={'col-12 col-md-6 pt-3'}>
              <label>Business Postcode</label>
                <input disabled={codeRequested} placeholder={'BUSINESS POSTCODE'} name={'postcode'} className={'input-boxes'} onChange={setVerifyFormField} value={verifyForm.postcode || ''} />
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-12 col-md-6 pt-3'}>
              <label>Mobile Number</label>
                <input disabled={codeRequested} placeholder={'MOBILE NUMBER'} name={'mobile_number'} className={'input-boxes'} onChange={setVerifyFormField} value={verifyForm.mobile_number || ''} />
                <small className={'pt-3 small-font'}>Please enter a plan.com mobile or a number already associated with your plan.com account</small>              
              </div>
              <div className={'col-12 col-md-6 pt-3'}>
                {!captchaSuccess ? 
                  (
                    <ReCAPTCHA sitekey={recaptcha_key} render="explicit" className="captcha-box" onChange={setCaptchaSuccess} />
                  ) 
                :
                  (
                  <div>captcha approved!</div>
                  )
                }
              </div>
            </div>
            </div>
            
            <IdentityHelp />

            <div className={'form-box'}>
            
            {captchaSuccess && !verified && (
            
            <div className={'row'}>
              
              <div className={'col-12'}>
                {errorMessage && 
                  <div>{errorMessage}
                   {/* <button className={'button'} onClick={tryAgain}>try again</button> */}
                  </div>
                }
              </div>
              <div className={'col-12 text-center'}>
                <button disabled={(!(captchaSuccess && formValid)) || pendingCreate} className={'btn btn-primary mb-4 mb-lg-0'} onClick={handleSendSecurityCode} >send security code</button>
              </div>
              <div className={'col-8 offset-2 mt-4'}>
                <small>The security code will be sent to the email address provided. If the email address you provide is not currently linked with the account but the company is recognised, the code will be emailed to the Billing contact or Authorised Signatory</small>
              </div>

              <SecurityHelp />
              {/* MIKE MOVED SECURITY HELP HERE - REMEMBER MONDAY - CHECK CODE WORKS  */}
            </div>
            
            )}
            
            
              
                
            {codeRequested && !verified && (
              <div className={'row'}>
                <div className={'col-lg-12 text-center mt-4'}>
                  <ReactCodeInput onChange={setVerifyCode} fields={5} />
                </div>
                <div className={'col-8 offset-2 mt-4'}>
                  <small>Please enter the security code here. The generation of the code can take up to 10 minutes. If you do not receive a code within that time period, please click here to resend or call +44(0)3300 88 18 18</small>
                </div>
              </div>
            
            )}
            
            {verified && (
              <div className={'verified'}>Verified please continue</div>
            )}

            
            
            <div className={'row'}>
              <div className={'col-12 text-right pt-3'}>
                <Link disabled={!verified} className={'btn btn-primary text-uppercase'} to="/details">Next Step</Link>
              </div>
            </div>
      
          </div>

          

          <div className={'d-flex justify-content-center text-center'}><p className="mt-4"><a href="https://www.plan.com/cancel" class="link" target="_blank">Click here</a> to read our full Terms &amp; Conditions before cancelling your contract.</p></div>

          {/* <a href="http://www.plan.com/downloads/PAC-disco-customer-guide.pdf" target="_blank"><img src={NeedHelpImage} alt="Download Guide" className="img-fluid mt-3" /></a> */}

          </div>
        </div>
      </div>
      </div>

  );
};

export default VerifyUser;
