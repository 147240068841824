import React, { useState }  from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NeedHelpImage from '../images/question-mark.svg'


export default props  => {


    const [modalVisible, setModalVisible]  = useState(false)
  
    const toggle = () => setModalVisible(!modalVisible)

    return (
        <>
        <div>
          <Modal isOpen={modalVisible} toggle={toggle} >
            <ModalHeader toggle={toggle}>SECURITY CODE<span className={'blue times'}>.</span></ModalHeader>
            <ModalBody>
              <p>Click SEND SECURITY CODE to send a five-digit authorisation code by email to the person at your business authorised to make changes on your account.</p>
              <p>If this is not you, it will be the person who signed the contract or the billing contact, depending on how your account was set up.</p>
              <p>The generation of the code can take up to 10 minutes. If you do not receive a code within that time period, please click on the link to the right of the code field to resend or call <strong>+44(0)3300 88 18 18.</strong></p>
            </ModalBody>
          </Modal>
        </div>

        <div className={'row help-section text-start'}>
            <div className={'col-12'}>
              <a onClick={toggle}><img src={NeedHelpImage} alt="Need Help?" className="help-icon" /> Need help with this section?</a>
            </div>
          </div>
        </>
    )
  }
  