import React from 'react'

export default ()=> (
<React.Fragment>
<div className={"row"}>
  <div className={"col-10 offset-1 col-lg-8 offset-lg-2 mt-5 pt-5 text-center"}>
    <p>In the event of a Service terminating before the end of its Service Term (as defined in your plan.com contract) for the above reason(s), in addition to any sums due to the end of the minimum term of the contract, you will also be expected to pay any current outstanding sums owed. Payment is required within 30 days of the amount be invoiced.</p>
    <h3>Fees: Disconnection of service(s)</h3><br />
    <p>Please be advised that as outlined in Clause 23 of your plan.com contract or the General Terms & Conditions document, you are required to provide 30 days’ written notice to terminate your contract. Completing and submitting this form constitutes such written notice.</p>
    <p>In addition, in the event of a Service terminating before the end of its Service Term, you may be liable for a Termination Fee, which is defined as an amount calculated by multiplying the remaining number of months of the Service Term by the monthly Charge for that Service as of the date of termination.</p>
    <h3>Fees: PAC requests</h3><br />
    <p>In the event of a Service terminating before the end of its Service Term, you may be liable for a Termination Fee which is defined as an amount calculated by multiplying the remaining number of months of the Service Term by the monthly Charge for that Service as of the date of termination.</p>
    <p>In the event of a Service terminating after the end of its Service Term, there is no termination fee.</p>
    <h3>Fees: STAC requests</h3><br />
    <p>In the event of a Service terminating before the end of its Service Term, you may be liable for a Termination Fee, which is defined as an amount calculated by multiplying the remaining number of months of the Service Term by the monthly Charge for that Service as of the date of termination.</p>
    <h3>Termination fees</h3><br />
    <p>These are examples of termination fees, which cannot be calculated in full until the Service has either been disconnected, transferred out or its usage has been fully restricted.</p>
    <p>The following does not constitute an invoice. Invoice(s) for any outstanding amounts owed will be generated and sent to your account’s billing contact.</p>
  </div>
</div>

      </React.Fragment>
)