import React, { useContext } from "react";
import Buyout from "./buyout";
import Terms from "./terms";
import Buttons from "./buttons";
import TalkaboutitImage from '../images/talk-about-it.svg'
import BetterofferImage from '../images/better-offer.svg'
import BusinessneedsImage from '../images/business-needs.svg'
import AppContext from '../context'

const Fees = props => {
  
  const {feesConfirmed, setFeesConfirmed} = useContext(AppContext)

  return (
    <>
    <div className={'header-panel'}>
      <div className={'row'}>
        <div className={'col-8 offset-2 text-center'}>
          <h1>IMPORTANT INFORMATION<br /><span class="blue">CONTRACTUAL OBLIGATIONS & FEES</span><span class="times blue">.</span></h1>
          <h2>Please note that transferring a service to a new provider or disconnecting a service before the minimum term agreed within your plan.com contract is classed as a Termination and as such, you may be required to pay Termination Fees (as defined in your plan.com contract).</h2>
        </div>
      </div>
    </div>
    <div className={"container text-left"}>
    <div className={'row tips-container'}>
        <div className={'col-10 offset-1 offset-lg-0 col-lg-4  position-relative'}>
          <div class="industry-box-inner"></div>
          <div class="industry-stat-box light-bg text-center">
            <img src={TalkaboutitImage} alt="Let's talk about it?" className="img-fluid icon-tips" />
            <h3>Want to talk<br />about it?</h3>
            <p>Not happy with how things have gone? Let’s have a chat and see if we can make things better.</p>
            <a href="https://www.plan.com/support" class="btn btn-primary">Talk to Us</a>
          </div>
        </div>
        <div className={'col-10 offset-1 offset-lg-0 col-lg-4 mt-4 mt-lg-0 position-relative'}>
        <div class="industry-box-inner"></div>
          <div class="industry-stat-box light-bg text-center">
            <img src={BetterofferImage} alt="Better offer elsewhere?" className="img-fluid icon-tips" />
            <h3>Better offer elsewhere?</h3>
            <p>With constantly evolving software, tariffs and bolt-ons, why not double check you're not missing out.</p>
            <a href="https://www.plan.com/" class="btn btn-primary">Double Check</a>
          </div>
        </div>
        <div className={'col-10 offset-1 offset-lg-0 col-lg-4 mt-4 mt-lg-0 position-relative'}>
        <div class="industry-box-inner"></div>
          <div class="industry-stat-box light-bg text-center">
            <img src={BusinessneedsImage} alt="Your business needs" className="img-fluid icon-tips" />
            <h3>Your<br />  business needs</h3>
            <p>Our award winning portal with over 100 easy to use tools and features can take you to the next level. </p>
            <a href="https://www.plan.com/features" class="btn btn-primary">view features</a>
          </div>
        </div>
      </div>

      <Terms />

      <Buyout />

      <div className={'form-box text-center'}>
        <input type={'checkbox'} defaultValue={feesConfirmed} onClick={(e) => setFeesConfirmed(!feesConfirmed)} /> I have read this information
      </div>

      <Buttons />
      <div className={'d-flex justify-content-center text-center'}><p className="mt-4"><a href="https://www.plan.com/cancel" target="_blank">Click here</a> to read our full Terms &amp; Conditions before cancelling your contract.</p></div>
    </div>
    </>
  );
};

export default Fees;
