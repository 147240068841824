import React, { useMemo, useContext } from "react";
import DatePicker from "react-datepicker";
import { addDays, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import ServiceListContext from "../context";

import ServiceHelp from "./service_help";

const ServiceList = props => {
  const {
    disconnectionServices,
    services,
    setDisconnectionServices
  } = useContext(ServiceListContext);

  let minDate = useMemo(() => addDays(new Date(), 30));
  const formatValueToState = value => format(value, "YYYY-MM-DD");
  const onServiceUpdated = (service_id, field, value) => {
    let new_disconnection_services = disconnectionServices.filter(
      service => service.service_id !== service_id
    );
    let disconnection_service = disconnectionServices.find(
      service => service.service_id === service_id
    );

    if (!disconnection_service) {
      disconnection_service = {
        service_id
      };
    } else {
      console.log("found", disconnection_service);
    }

    let new_service = {
      ...disconnection_service,
      [field]: value
    };

    console.log("new service", new_service);
    setDisconnectionServices(new_disconnection_services.concat([new_service]));
  };

  return (
    (services === null && <div>Loading service list...</div>) ||
    (Array.isArray(services) && services.length === 0 && (
      <div>No connected services found</div>
    )) || (
      <React.Fragment>
        <div className={"row service-list"}>
          <div className={"col-12"}>
          <div className={"row service-list-header"}>
            <div className={"col-4"}>
              <b>Service Details /<br />Tariff Details</b>
            </div>
            <div className={"col-8"}>
              <div className={"row"}>
                <div className={"d-none d-xl-block col-xl-2 text-center"}>
                  <b>Term</b>
                </div>                
                <div className={"d-none d-xl-block col-xl-2 text-center"}>
                  <b>Term Dates</b>
                </div>                
                <div className={"col-3 col-xl-2 text-center"}>
                  <b>Term Fees &amp; Charges*</b>
                </div>                
                <div className={"col-2 col-xl-1 text-center"}>
                  <b>Keep Service</b>
                </div>

                <div className={"col-2 col-xl-1 text-center"}>
                  <b>PAC (transfer)</b>
                </div>
                <div className={"col-2 col-xl-1 text-center"}>
                  <b>STAC</b>
                </div>
                <div className={"col-3 text-center"}>
                  <b>Disconnection**</b>
                </div>
              </div>
            </div>
          </div>
          <div className={"service-list-content"}>
            {services.map(service => {
              let disconnection_service = disconnectionServices.find(
                disconnection_service =>
                  disconnection_service.service_id === service.service_id
              );
              return (
                <div
                  key={service.service_id}
                  className={"row border-bottom d-flex"}
                >
                  <div className={"col-4 text-left align-self-center"}>
                    <p>{service.service_reference} - {service.service_name}</p>
                    <p>{service.tariff}</p>
                    <div className={"d-xl-none"}>
                      <p>{service.contract_start_date} - {service.contract_end_date}</p>
                    </div>
                  </div>
                  <div className={"col-8"}>
                    <div className={"row"}>
                      <div className={"d-none d-xl-block col-xl-2 text-center align-self-center"}>
                        <p>{service.contract_term}m</p>
                      </div>                      
                      <div className={"d-none d-xl-block col-xl-2 text-center align-self-center"}>
                        <p>{service.contract_start_date}</p>
                        <p>{service.contract_end_date}</p>
                      </div>                      
                      <div className={"col-3 col-xl-2 text-center align-self-center"}>
                        <p>£{service.buyout_value}</p>
                        <p>£{service.committed_spend}</p>
                        
                      </div>                      
                      <div className={"col-2 col-xl-1 text-center align-self-center"}>
                        <p>
                          <input
                            type={"radio"}
                            checked={
                              !disconnection_service ||
                              (disconnection_service &&
                                disconnection_service.type === 0) ||
                              false
                            }
                            onChange={() =>
                              onServiceUpdated(service.service_id, "type", 0)
                            }
                          />
                        </p>
                      </div>
                      <div className={"col-2 col-xl-1 text-center align-self-center"}>
                        <p>
                          <input
                            type={"radio"}
                            checked={
                              (disconnection_service &&
                                disconnection_service.type &&
                                disconnection_service.type === 1) ||
                              false
                            }
                            onChange={() =>
                              onServiceUpdated(service.service_id, "type", 1)
                            }
                          />
                        </p>
                      </div>
                      <div className={"col-2 col-xl-1 text-center align-self-center"}>
                        <p>
                          <input
                            type={"radio"}
                            checked={
                              (disconnection_service &&
                                disconnection_service.type &&
                                disconnection_service.type === 5) ||
                              false
                            }
                            onChange={() =>
                              onServiceUpdated(service.service_id, "type", 5)
                            }
                          />
                        </p>
                      </div>
                      
                      <div className={"col-3 align-self-center"}>
                        <div className={"row"}>
                          <div className={"col-lg-6 text-center align-self-center"}>
                            <input
                              type={"radio"}
                              checked={
                                (disconnection_service &&
                                  disconnection_service.type &&
                                  disconnection_service.type === 2) ||
                                false
                              }
                              onChange={() =>
                                onServiceUpdated(service.service_id, "type", 2)
                              }
                            />
                          </div>
                          {disconnection_service &&
                            disconnection_service.type === 2 && (
                              <div
                                className={
                                  "col-lg-6 text-center align-self-center"
                                }
                              >
                                <DatePicker
                                  minDate={minDate}
                                  placeholderText={'Choose a Date'}
                                  value={
                                    (disconnection_service &&
                                      disconnection_service.date) ||
                                    null
                                  }
                                  onChange={date =>
                                    onServiceUpdated(
                                      service.service_id,
                                      "date",
                                      formatValueToState(date)
                                    )
                                  }
                                />
                              </div>
                            )}
                        </div>
                      </div>                      
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          </div>
        </div>

        <div className={"row"}>
          <div className={"col-12 pt-5 pb-3"}>
          <small>
              * Fixed monthly charges including pre-agreed line rental, contractual monthly bolt-ons or tariff addons. Estimated Termination Fees. Exact Fees cannot be provided until the point when a service is tranferred
              away or disconnected from the plan.com network.
            </small><br />
            <small>
              ** Disconnections require a minimum of 30 day’s notice, measured
              from the point of authorisation by a person approved on your
              plan.com account such as the account holder or billing contact.
            </small>
          </div>
        </div>

        <ServiceHelp />
      </React.Fragment>
    )
  );
};

export default ServiceList;
