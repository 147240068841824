import React, { useContext } from "react";
import AppContext from "../context";

import ReasonMessage from "./reason_message"

export default () => {
  const {
    disconnectionReasons,
    setDisconnectionReason,
    disconnectionReason
  } = useContext(AppContext);

  return (
    <React.Fragment>
      <div className={"row mt-5"}>
        <div className={"col-10 offset-1 col-lg-6 offset-lg-3 text-center"}>
          <h2 className={'reason'}>Reason for transfer or disconnection<span className={'times blue'}>.</span></h2>
        </div>
      </div>

      <div className={"row my-5"}>
        <div className={"col-10 offset-1 col-lg-6 offset-lg-3"}>
          <div className={"dropdown-select"}>
          <select
            className="form-control"
            value={disconnectionReason}
            onChange={e => setDisconnectionReason(e.target.value)}
          >
            <option value={0}>please select</option>
            {disconnectionReasons && disconnectionReasons.map(reason => (
              <option key={reason.id} value={reason.id}>
                {reason.label}
              </option>
            ))}
          </select>
          </div>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-10 offset-1 col-lg-6 offset-lg-3 text-center"}>
          <p>Please choose your reason for disconnection options. Unwilling to disclose the reason? Select 'I’d rather not say'</p>
        </div>

        <ReasonMessage />
        
      </div>
    </React.Fragment>
  );
};
