import React, { useState }  from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import NeedHelpImage from '../images/question-mark.svg'


export default props  => {


    const [modalVisible, setModalVisible]  = useState(false)
  
    const toggle = () => setModalVisible(!modalVisible)

    return (
        <>
        <div>
          <Modal isOpen={modalVisible} toggle={toggle} >
            <ModalHeader toggle={toggle}>IDENTITY & VALIDATION</ModalHeader>
            <ModalBody>
            <p>The next step is to confirm that we’ve got the right business account. Please check the details and confirm that the detail are correct.</p>
            <p>If they are incorrect, give us a call on call <strong>+44(0)3300 88 18 18</strong>.</p>
            </ModalBody>
          </Modal>
        </div>

        <div className={'row help-section text-start'}>
            <div className={'col-12'}>
              <a onClick={toggle}><img src={NeedHelpImage} alt="Need Help?" className="help-icon" /> Need help with this section?</a>
            </div>
          </div>
        </>
    )
  }
  