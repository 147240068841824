import React, { useState }  from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


export default props  => {


    const [modalVisible, setModalVisible]  = useState(false)
  
    const toggle = () => setModalVisible(!modalVisible)

    return (
        <>
        <div>
          <Modal isOpen={modalVisible} toggle={toggle} >
            <ModalHeader toggle={toggle}>What’s the difference between a PAC, STAC and a disconnection</ModalHeader>
            <ModalBody>
            <h2>What is a PAC?</h2>
            <p>If you want to leave a mobile network but keep your mobile number, you will need a Porting Authorisation Code (PAC). Your PAC is a unique identifier (usually nine characters long and in the format “ABC123456”) used to transfer a mobile number from one service provider to another. </p>
            <p>A PAC is valid for a period of 30 calendar days from the day of PAC issue. Your request to your new Service Provider must be submitted within the 30 calendar day time period.</p>
            <h2>What is a STAC?</h2>
            <p>If you choose to cancel your plan.com contract by removing a service from our network and connect a new service on a different service with a new number, you can request a Service Termination Authorisation Code (STAC).</p>
            <p>A STAC affords you exactly the same rights as a PAC except it will not transfer your number.</p>
            <p>Please note that your existing service will not disconnect from the plan.com network until the STAC has been received and initiated by the new service provider.</p>
            <h2>What is a disconnection?</h2>
            <p>If you want to leave a mobile network and do not want to keep your mobile number, this is classed as a disconnection. </p>
            <p>As outlined in your contract with plan.com, we require at least 30 days of notice to disconnect a service, regardless of the minimum term.</p>
            </ModalBody>
          </Modal>
        </div>

        <div className={'row mt-4 mb-4'}>
            <div className={'col-10 d-flex align-tems-center'}><h2 className={'question'} >What's the difference between a PAC, STAC and a disconnection?</h2></div><div className={'col-2 text-end'}><button className={'btn btn-primary'} onClick={toggle}>Click here</button></div>
        </div>
        </>
    )
  }
  