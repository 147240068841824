const API_URL = "";


 function handleErrors(response) {
    if (!response.ok) {
        throw  response
    }
    
    return response;
}

export function findService(number) {

    return new Promise((resolve, reject) => {
        fetch(API_URL + "/service/" + number  + "/verify" )
        .then(handleErrors)
        .then(res => res.json())
        .then(res => { console.log("res",res); return res})
        .then(resolve)
        .catch(reject)

    })
}

export function serviceList(request_token) {

    return new Promise((resolve, reject) => {
        fetch(API_URL + "/service_list?request_token=" + request_token )
        .then(handleErrors)
        .then(res => res.json())
        .then(res => res.data)
        .then(resolve)
        .catch(reject)

    })
}

export function getBuyoutData(request_token, service_ids, termination_dates,disconnection_type_ids) {
    return new Promise((resolve, reject) => {
        fetch(
            API_URL + `/buyout_data?request_token=${request_token}&service_ids=${service_ids}&`+
            Object.keys(termination_dates).map(function(x){
                return `termination_dates[${x}]=${termination_dates[x]}`;
            }).join('&') + `&` +             
            Object.keys(disconnection_type_ids).map(function(x){
                return `disconnection_type_ids[${x}]=${disconnection_type_ids[x]}`;
            }).join('&')
         )
        .then(handleErrors)
        .then(res => res.json())
        .then(res => res.data)
        .then(resolve)
        .catch(reject)
    })
}


export const createRequest = (request) => {
    return new Promise((resolve, reject) => {
        fetch(
            API_URL + "/create_request?" + Object.keys(request).map(function(x){
                return `${x}=${request[x]}`;
            }).join('&')
         )
        .then(handleErrors)
        .then(res => res.json())
        .then(res => res.data)
        .then(resolve)
        .catch(reject)

    })    
}
export const validateCode = (request_token, code) => {
    return new Promise((resolve, reject) => {
        fetch(
            API_URL + `/validate_code?request_token=${request_token}&code=`+ code
         )
        .then(handleErrors)
        .then(res => res.json())
        .then(res => res.data)
        .then(resolve)
        .catch(reject)

    })    
}
export const submitRequest = (request_token, disconnection_reason_id, service_ids, termination_dates, disconnection_type_ids) => {
    return new Promise((resolve, reject) => {
        fetch(
            API_URL + `/submit_request?request_token=${request_token}&disconnection_reason_id=${disconnection_reason_id}&service_ids=${service_ids}&`+
            Object.keys(termination_dates).map(function(x){
                return `termination_dates[${x}]=${termination_dates[x]}`;
            }).join('&')  + `&` +
            Object.keys(disconnection_type_ids).map(function(x){
                return `disconnection_type_ids[${x}]=${disconnection_type_ids[x]}`;
            }).join('&')            
         )        
         .then(handleErrors)
        .then(res => res.json())
        .then(res => res.data)
        .then(resolve)
        .catch(reject)

    })    
}
export const getRequestDetails = (request_token) => {
    return new Promise((resolve, reject) => {
        fetch(
            API_URL + `/request_details?request_token=${request_token}`
        )
        .then(handleErrors)
        .then(res => res.json())
        .then(res => res.data)
        .then(resolve)
        .catch(reject)

    })    
}
export const getDisconnectionReasons = () => {
    return new Promise((resolve, reject) => {
        fetch(
            API_URL + '/disconnection_reasons'
        )
        .then(handleErrors)
        .then(res => res.json())
        .then(res => res.data)
        .then(resolve)
        .catch(reject)

    })    
}
