import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from '../context'


export default () => {
  const { disconnectionServices, confirmLiabilities, disconnectionReason, businessDetailsCorrect } = useContext(AppContext)

  const buttonDisabled = !businessDetailsCorrect || disconnectionServices.length===0 || !confirmLiabilities || disconnectionReason === 0 || disconnectionServices.length > 25

  return (<React.Fragment>
    <div className={'row'}>
        <div className={'col-12 text-uppercase text-center red'}>
        {disconnectionServices.length===0 && "please choose services to disconnect"}
        {disconnectionServices.length>25 && "please contact customer services to disconnect more than 25 services"}
        {disconnectionServices.length>0 && disconnectionServices.length<=25 && !confirmLiabilities && "please confirm liabilities"}
        {disconnectionServices.length>0 && disconnectionServices.length<=25 && confirmLiabilities  && disconnectionReason === 0 && "please select reason for disconnection"}
        </div>
    </div>
    <div className={"row p-3"}>
      <div className={"col-lg-2 order-lg-3 mb-5 mb-lg-0 text-center d-flex justify-content-center align-items-lg-end flex-column"}>
        <Link disabled={buttonDisabled} className={'mt-3 mt-lg-0 btn btn-primary ' + (disconnectionServices.length >0 )}  to="/fees">
          Next Step
        </Link>
      </div>
      
      <div className={"col-6 col-lg-2 order-lg-2 text-center d-flex justify-content-lg-end align-items-center"}>
        <Link className={"btn btn-secondary"} to="/">
          Back
        </Link>
      </div>
      <div className={"col-6 col-lg-8 order-lg-1 text-center justify-content-end justify-content-lg-start d-flex align-items-center"}>
        <Link className={"btn btn-danger"} to="/">
          Cancel Request
        </Link>
      </div>
    </div>
  </React.Fragment>)
};
