import React, { useState }  from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NeedHelpImage from '../images/question-mark.svg'


export default props  => {


    const [modalVisible, setModalVisible]  = useState(false)
  
    const toggle = () => setModalVisible(!modalVisible)

    return (
        <>
        <div>
          <Modal isOpen={modalVisible} toggle={toggle} >
            <ModalHeader toggle={toggle}>Service selection</ModalHeader>
            <ModalBody>
            <p>Please identify the service(s) you want to change by selecting to transfer it to a new provider (request PAC), disconnecting the service using a STAC or disconnection if you do not want to transfer or keep the number, or checking the terms fees.</p>
            <h2>PAC</h2>
            <p>If you want to leave a mobile network but keep your mobile number, you will need a Porting Authorisation Code (PAC). Your PAC is a unique identifier (usually nine characters long and in the format “ABC123456”) used to transfer a mobile number from one service provider to another.</p>
            <p>A PAC is valid for a period of 30 calendar days from the day of PAC issue. Your request to your new Service Provider must be submitted within the 30 calendar day time period.</p>
            <h2>STAC</h2>
            <p>If you choose to cancel your plan.com contract by removing a service from our network and connect a new service on a different service with a new number, you can request a Service Termination Authorisation Code (STAC).</p>
            <p>A STAC affords you exactly the same rights as a PAC except it will not transfer your number.</p>
            <p>Please note that your existing service will not disconnect from the plan.com network until the STAC has been received and initiated by the new service provider.</p>
            <h2>Disconnection</h2>
            <p>If you want to leave a mobile network and do not want to keep your mobile number, this is classed as a disconnection.</p>
            <p>As outlined in your contract with plan.com, we require at least 30 days of notice to disconnect a service, regardless of the minimum term.</p>
            <h2>Check fees</h2>
            <p>If you’ve come to this site to check the minimum length of your contract or the Termination Fees that would be applied if you decide to terminate the contract early, please choose ‘Check Fees’.</p>  
            <p>Your Termination Fees and contract lengths will be given on the next page. If you do not want to progress any further, simply close your browser or tap the ‘Cancel Request’ button. If you do, you can go back to this page and change your section from ‘Check Fees’ to the relevant transfer or disconnection requests.</p>
            </ModalBody>
          </Modal>
        </div>

        <div className={'row help-section text-start'}>
            <div className={'col-12'}>
              <a onClick={toggle}><img src={NeedHelpImage} alt="Need Help?" className="help-icon" /> Need help with this section?</a>
            </div>
          </div>
        </>
    )
  }
  