import React, { useState }  from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import NeedHelpImage from '../images/question-mark.svg'

export default props  => {


    const [modalVisible, setModalVisible]  = useState(false)
  
    const toggle = () => setModalVisible(!modalVisible)

    return (
        <>
        <div>
          <Modal isOpen={modalVisible} toggle={toggle} >
            <ModalHeader toggle={toggle}>Solvency</ModalHeader>
            <ModalBody>
            <p>It’s important that we’re notified of any changes to your business that impair your ability to pay your bills. Please tick the box to confirm that your business is trading, or leave it blank if you are not.</p>
            </ModalBody>
          </Modal>
        </div>

         &nbsp;<a onClick={toggle}><img src={NeedHelpImage} alt="Need Help?" className="help-icon" /></a>
         
        </>
    )
  }
  