import React, { useContext, useState } from 'react'
import { Redirect, Link } from "react-router-dom";
import AppContext from '../context'
import { submitRequest } from '../Api'
import format from 'date-fns/format'

export default () => {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const {feesConfirmed, buyoutData, disconnectionServices, requestToken, disconnectionReason } = useContext(AppContext)

  const handleSubmitRequest = () => {
    setSubmitting(true)
    submitRequest(
      requestToken,
      disconnectionReason,
      disconnectionServices.map(service => service.service_id),
      disconnectionServices.reduce((previous, service) => ({ ...previous, [service.service_id]: service.date || format(new Date(), 'YYYY-MM-DD') }), {}),
      disconnectionServices.reduce((previous, service) => ({ ...previous, [service.service_id]: service.type }), {}),
    )
      .then(response => {
        setSubmitted(true)
      })


  }
  const got_buyout_data = !!buyoutData.buyout_data
  const submitDisabled = !got_buyout_data || !feesConfirmed || submitting



  return (
    <React.Fragment>
      {(submitted && <Redirect to="/submitted" push />) ||
        <div className={"row pt-5"}>
          <div className={"col-lg-2 order-lg-3 mb-5 mb-lg-0 text-center d-flex justify-content-center align-items-lg-end flex-column"}>
            <button disabled={submitDisabled} className={"btn btn-primary"} onClick={handleSubmitRequest}>
              {submitting ? "please wait ..." : "Submit Request"}
            </button>
          </div>
          <div className={"col-6 col-lg-2 order-lg-2 text-center d-flex justify-content-lg-end align-items-center"}>
            <Link className={"btn btn-secondary"} to="/details">
              Back
            </Link>
          </div>
          <div className={"col-6 col-lg-8 order-lg-1 text-center justify-content-end justify-content-lg-start d-flex align-items-center"}>
            <Link className={"btn btn-danger"} to="/">
              Cancel Request
            </Link>
          </div>


        </div>}
    </React.Fragment>

  )

}
