import React, { useState }  from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import NeedHelpImage from '../images/question-mark.svg'
export default props  => {


    const [modalVisible, setModalVisible]  = useState(false)
  
    const toggle = () => setModalVisible(!modalVisible)

    return (
        <>
        <div>
          <Modal isOpen={modalVisible} toggle={toggle} >
            <ModalHeader toggle={toggle}>IDENTITY & VALIDATION<span className={'blue times'}>.</span></ModalHeader>
            <ModalBody>
            <p>Please double check all information is correct and that you are using the billing email address and postcode in the corresponding fields and not your personal information.</p>
            <p>If you are not the authorised plan.com contact, you can still submit your details and complete each step in the form. However, the initial security code and the final authorisation of the request will be emailed to the authorised account holder or billing contact.</p>
            </ModalBody>
          </Modal>
        </div>

        <div className={'row help-section text-start'}>
            <div className={'col-12'}>
              <a onClick={toggle}><img src={NeedHelpImage} alt="Need Help?" className="help-icon" /> Need help with this section?</a>
            </div>
          </div>
        </>
    )
  }
  