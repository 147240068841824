import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./App.css";
import "./goodbye.scss";

import SecureRoute from "./SecureRoute";
import VerifyUser from "./VerifyUser";
import RequestDetails from "./RequestDetails";
import Fees from "./Fees";
import Submitted from "./Submitted";
import RequestValidated from "./RequestValidated";
// import WhyPlan from "./WhyPlan";
import AppContext from "./context";
import { serviceList, getRequestDetails, getDisconnectionReasons } from "./Api";

// import CodeEntry from './CodeEntry';

const App = props => {
  const recaptcha_key = "6LdmrpkUAAAAAGyDONxcjuKBIe36RtfLkWMj4Q0P";

  const [services, setServices] = useState(null);
  const [disconnectionServices, setDisconnectionServices] = useState([]);

  const [buyoutData, setBuyoutData] = useState({});
  
  const [requestDetails, setRequestDetails] = useState({});

  const [confirmLiabilities, setConfirmLiabilities] = useState(false);

  const [disconnectionReasons, setDisconnectionReasons] = useState([]);
  const [disconnectionReason, setRealDisconnectionReason] = useState(0);
  
  const [verifyForm, setVerifyForm] = useState({
    // full_name: "Ben Cummins",
    // mobile_number: "07720460444",//"07748061929",
    // postcode: "DE5 3QL",//CA28 7HS",
    // business_name: "ben  and co",
    // email_address: "Ben@bencummins.com"
  });
  const [verifyCode, setVerifyCode] = useState("");

  const [codeRequested, setCodeRequested] = useState(false);

  const [requestToken, setRequestToken] = useState(null);
  const [verified, setVerified] = useState(null);
  const [feesConfirmed, setFeesConfirmed] = useState(false);

  const [businessDetailsCorrect, setBusinessDetailsCorrect] = useState(false)

  const setDisconnectionReason = reason => {
    console.log("setDisconnectionReason", reason);
    setRealDisconnectionReason(parseInt(reason));
  };

  useEffect(() =>{
    getDisconnectionReasons().then(setDisconnectionReasons)

    
    // setDisconnectionReasons([
    //   { id: 16, label: "Check Fees" },
    //   { id: 1, label: "No longer in use" },
    //   { id: 2, label: "User leaving the company" },
    //   { id: 3, label: "Signal issue" },
    //   { id: 4, label: "Mis-selling of contract" },
    //   { id: 5, label: "No buyout/discounts provided" },
    //   { id: 6, label: "Contract length issue" },
    //   { id: 7, label: "Didn’t know it was a contract" },
    //   { id: 8, label: "Insolvency, liquidation or in administration" },
    //   { id: 9, label: "Bankruptcy, IVAs or CVAs" },
    //   { id: 10, label: "Better deal elsewhere" },
    //   { id: 11, label: "Poor customer service" },
    //   { id: 12, label: "Billing issue" },
    //   { id: 13, label: "Deceased or Seriously ill" },
    //   { id: 14, label: "Ceased trading" },
    //   { id: 15, label: "I’d rather not say" }
    // ])

  },[])




  useEffect(() => {
    if (verified) {
      serviceList(requestToken)
        .then(setServices)
        .catch(e => console.log("error", e) || e);

        getRequestDetails(requestToken)
        .then(setRequestDetails)
        .catch(e => console.log("error", e) || e);

    }
  }, [verified, requestToken]);

  return (
    <div className="App mb-5">
      <div className="header">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <a href="https://www.plan.com"><img src="https://www.plan.com/assets/images/main/logo.svg" alt="plan.com" className="header-logo" /></a>
              <div>
              <a href="https://www.plan.com" class="btn btn-primary d-none d-lg-inline">visit plan.com</a>
              <a href="https://www.plan.com" class="btn btn-primary">login to my.plan</a>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <AppContext.Provider
        value={{
          services,
          disconnectionReasons,

          disconnectionReason,
          setDisconnectionReason,
          
          disconnectionServices,
          setDisconnectionServices,

          confirmLiabilities,
          setConfirmLiabilities,

          verifyForm,
          setVerifyForm,

          recaptcha_key,

          buyoutData,
          setBuyoutData,

          verifyCode,
          setVerifyCode,

          codeRequested,
          setCodeRequested,

          setRequestToken,
          requestToken,

          businessDetailsCorrect, 
          setBusinessDetailsCorrect,

          verified,
          setVerified,

          requestDetails,

          feesConfirmed,
          setFeesConfirmed
          

          }}
      >

        <Router>
          <Route path="/" exact component={VerifyUser} />
          <Route path="/validated" exact component={RequestValidated} />
          <SecureRoute path="/details" exact component={RequestDetails} />
          <SecureRoute path="/fees" exact component={Fees} />
          <SecureRoute path="/submitted" exact component={Submitted} />
        </Router>

        
      </AppContext.Provider>
    </div>
  );
};

export default App;
