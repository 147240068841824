import React, { useContext, useEffect, useState } from "react";
import format from 'date-fns/format'



import AppContext from "../context";
import { getBuyoutData } from "../Api";

import { sprintf } from "sprintf-js"

const BuyoutTotal = props => {
  const { buyoutData } = useContext(AppContext);

  return (
    <div className={"form-box text-center"}>
      <strong>Current Estimated Final Invoice Amount for the requested service(s) is:</strong><br />
      <h1 className={"text-danger mt-2"}>£{sprintf('%.2f', buyoutData.total_buyout)}<sup>*</sup></h1>
      <p>*Early termination fees are subject to 20% VAT from April 1st 2022</p>
    </div>
  );
};

const BuyoutBreakdown = props => {

  const { buyoutData } = useContext(AppContext);
  
  return <div className={"service-list-double mt-5 mb-5"}>
      <div className={'row pb-2'} >
        <div className={'col'}><b>Mobile number</b></div>
        <div className={'d-none d-md-block col'}><b>Tariff details</b></div>
        <div className={'col'}><b>Fixed Charges*</b></div>
        <div className={'col'}><b>Contract Signed</b></div>
        <div className={'d-none d-md-block col'}><b>Term</b></div>
        <div className={'col'}><b>Term Date</b></div>
        <div className={'col'}><b>Est. Term Fees **</b></div>

      </div>

    {buyoutData.buyout_data && buyoutData.buyout_data.map(item => (
      <div className={'row border-bottom pt-2 pb-2'} key={item.service_id}>
        <div className={'col '}><p>{item.number}</p></div>
        <div className={'d-none d-md-block col'}><p>{item.package}</p></div>
        <div className={'col'}><p>£{sprintf('%.2f', item.price)}</p></div>

        <div className={'col'}><p>{format(item.connection_date, 'DD MMM YY')}</p></div>
        <div className={'d-none d-md-block col '}><p>{item.contract_term}m</p></div>
        <div className={'col'}><p>{format(item.disconnection_date, 'DD MMM YY')}</p></div>
        <div className={'col'}><p>£{sprintf('%.2f', parseFloat(item.unbilled_value)+parseFloat(item.unbilled_usage_total))}</p></div>

      </div>
      
    ))}
  </div>
};

const Buyout = props => {

  const {setBuyoutData, disconnectionServices, requestToken} = useContext(AppContext)
const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true);
    if (disconnectionServices.length > 0) {
      getBuyoutData(
        requestToken,
        disconnectionServices.map(service=>service.service_id),
        disconnectionServices.reduce((previous, service)=>({...previous, [service.service_id]: service.date || format(new Date(), 'YYYY-MM-DD')  }), {}),
        disconnectionServices.reduce((previous, service)=>({...previous, [service.service_id]: service.type || 0 }), {})
      )
      .then(setBuyoutData)
      .then(()=>setLoading(false))
      
      
    }

  }, [disconnectionServices]);


  return (
    (loading && <div>Calculating buyout</div>)
    ||
    <div className={"mt-3 mb-3"}>
    <div className={"row"}>
      <div className={"col-lg-8 offset-lg-2 col-12"}>
        <BuyoutTotal />
      </div>
    </div>
    <div className={"row mt-2"}>
      <div className={"col-lg-8 offset-lg-2 col-12 text-center"}>
        <p><strong>Please remember:</strong> in addition to any sums due to the end of the minimum term of the contract, you will also be expected to pay any current outstanding sums owed. Payment is required within 30 days of the amount being invoiced.</p>
      </div>
    </div>
    
      <BuyoutBreakdown />

      <div><small>* Fixed monthly charges including pre-agreed line rental, contractual monthly bolt-ons or tariff addons.</small></div>
      <div><small>** Estimated Termination Fees. Exact Fees cannot be provided until the point when a service is transferred away or disconnected from the plan.com network. Early termination fees are subject to 20% VAT from April 1st 2022</small></div>


    </div>
  );
};
export default Buyout;
