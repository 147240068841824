import React, {useContext} from 'react'
import { Redirect, Route } from 'react-router-dom'

import AppContext from "./context";

const SecureRoute = ({ component: Component, ...rest }) => {

  const { requestToken } = useContext(AppContext)

  return (
    <Route
      {...rest}
      render={props =>
        requestToken ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default SecureRoute