import React, { useContext}  from "react";
import AppContext from '../context'

import AddressHelp from './address_help'


export default () => {

  const {requestDetails, setBusinessDetailsCorrect, businessDetailsCorrect} = useContext(AppContext)

  return <React.Fragment>
            <div className={'row'}>
              <div className={'col-10 offset-1 col-lg-6 offset-lg-3 mb-5 mt-5 text-center'}>
                <p>Please complete the following details. It’s important that you double check your requests because once a request has been activated, it cannot be reversed.</p>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-10 offset-1 offset-md-0 col-md-6'}>
                <label>Business Name</label>
                <span class={'disabled-box'}>{requestDetails.business_name}</span>
              </div>
              <div className={'col-10 offset-1 offset-md-0 col-md-6 pt-3 pt-md-0'}>
                <label>Billing Address</label>
                <span class={'disabled-box'}>{requestDetails.billing_address && requestDetails.billing_address.map(line => (
                  <div key={line}>{line}</div>
                ))}</span>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-10 offset-1 offset-md-0 col-md-6'}>
                <label>Billing Postcode</label>
                <span class={'disabled-box'}>{requestDetails.billing_postcode}</span>
              </div>
              <div className={'col-10 offset-1 offset-md-0 col-md-6 pt-3 pt-md-0'}>
                <label>plan.com customer ID</label>
                <span class={'disabled-box'}>{requestDetails.customer_id}</span>
              </div>
            </div>
            <div className={'row'}>
              <div className={"col-10 offset-1 offset-md-0 text-center"}>
                <p>Please tick below to indicate that the details to the left are correct.  If they are incorrect, please contact us to discuss.</p>
              </div>
            </div>
            <div className={'row'}>
                <div className={'col-12 pb-3'}>
                    <div className={'form-box d-flex align-self-center justify-content-center'}>
                        <input type={'checkbox'} defaultChecked={businessDetailsCorrect} onChange={(e) => setBusinessDetailsCorrect(!businessDetailsCorrect)} /><p>&nbsp;&nbsp; These business details are correct</p>
                    </div>
                </div>
            </div>
            <div className={"row"}>
              <div className={"col-12"}>
                <AddressHelp />
              </div>
            </div>
    
  </React.Fragment>
}
